import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchMetaData } from "./reducers/metaSlice";
import { fetchContentData } from "./reducers/contentSlice";

import Article from "./components/Article";
import Home from "./components/Home";
import SearchResult from "./components/SearchResult";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMetaData());
    dispatch(fetchContentData());
  });

  return (
    <Router>
      <div className='App'>
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/search'>
            <SearchResult />
          </Route>
          <Route path='/article/:slug'>
            <Article />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
