import { useState } from 'react';
import styled from 'styled-components';

import { COLOR_GRAY } from '../constants/styles'

const Wrapper = styled.div`
  + p {
    margin-top: 8px;
    @media (min-width: 768px) {
      margin: 0 0 0 60px;
    }
  }
`

const Select = styled.select`
  padding: 12px 20px;
  text-align-last: center;
  color: ${COLOR_GRAY};
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  appearance: none;
  & + & {
    margin: 0 0 0 12px;
  }
`

function DateSelector(props) {
  const now = new Date()
  const currentYear = now.getFullYear()

  const [year, setYear] = useState(props.year || '2019')
  const [month, setMonth] = useState(props.month || '01')
  const [maxMonth, setMaxMonth] = useState(getMaxMonth(props.year))

  function getMaxMonth(year) {
    if (Number(year) === currentYear) {
      return now.getMonth() + 1
    }
    return 12
  }

  function handleYearChange(e) {
    const yearSelected = e.target.value
    setYear(yearSelected)
    setMaxMonth(getMaxMonth(yearSelected))
    props.onChange(`${yearSelected}-${month}`)
  }

  function handleMonthChange(e) {
    const monthSelected = e.target.value
    setMonth(monthSelected)
    props.onChange(`${year}-${monthSelected}`)
  }

  function renderYearOptions() {
    let options = []
    for(let start = 2019; start <= currentYear; start += 1) {
      options.push(<option key={start} value={start}>{ start }</option>)
    }
    return options
  }

  function renderMonthsOptions() {
    return (
      Array(maxMonth)
        .fill()
        .map((_, i) => {
          const value = `${i + 1}`.padStart(2, '0')
          return <option key={value} value={value}>{value}</option>
        })
    )
  }

  return (
    <Wrapper>
      <Select value={year} onChange={handleYearChange}>
        {renderYearOptions()}
      </Select>
      <Select value={month} onChange={(e) => handleMonthChange(e)}>
        {renderMonthsOptions()}
      </Select>
    </Wrapper>
  )
}

export default DateSelector;