import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";

import { COLOR_GREEN, COLOR_GREEN_TEXT, COLOR_GRAY } from "../constants/styles";
import { KEYWORDS_SUGGEST } from "../constants/search";
import Footer from "./Footer";
import Header from "./Header";
import SearchBar from "./SearchBar";
import SearchAdvanced from "./SearchAdvanced";
import Contributors from "./Contributors";
import Cooperation from "./Cooperation";

const Wrapper = styled.section`
  padding: 88px 16px 48px;
  @media (min-width: 768px) {
    padding: 120px 0 0;
  }
`;

const FirstScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  min-height: calc(100vh - 88px);
  @media (min-width: 768px) {
    min-height: 100vh;
  }
`;

const Title = styled.h1`
  margin: 0;
  color: ${COLOR_GREEN_TEXT};
  text-align: center;
  font-weight: 200;
  font-size: 32px;
  line-height: 40px;
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 44px;
  }
`;

const Text = styled.p`
  max-width: 512px;
  margin: 12px 0 0;
  color: ${(props) => (props.green ? COLOR_GREEN_TEXT : COLOR_GRAY)};
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0.005em;

  @media (min-width: 768px) {
    margin: ${(props) => (props.green ? "12px" : "24px")} 0 0;
  }
`;

const Button = styled.button`
  margin: 24px 0 0;
  padding: 12px 16px;
  color: ${COLOR_GREEN_TEXT};
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.005em;
  background-color: transparent;
  border: 1px solid ${COLOR_GREEN};
  border-radius: 2px;
  &:hover {
    background: rgba(16, 155, 149, 0.05);
  }
  @media (min-width: 768px) {
    margin: 240px 0 0;
  }
  @media (min-width: 1024px) {
    margin: 200px 0 0;
  }
`;

const SuggestKeywords = styled.div`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.005em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  direction: column;
`;

const KeywordLink = styled(Link)`
  color: ${COLOR_GREEN_TEXT};
  display: inline-block;
  margin-top: 10px;
  &:hover {
    text-decoration-line: underline;
  }
  & + & {
    margin-left: 16px;
  }
`;

const ListWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
`;

const Hint = styled.div`
  margin-top: 24px;
`;

function Home() {
  let history = useHistory();

  function handleClickRandomKeywords() {
    const randomIndex = Math.floor(
      Math.random() * Math.floor(KEYWORDS_SUGGEST.length)
    );

    history.push(`/search?q=${KEYWORDS_SUGGEST[randomIndex]}`);
  }

  function handleSearch(keyword) {
    history.push(`/search/?q=${keyword}`);
  }

  function renderSuggestKeywords(num) {
    const lotArray = Array.from(
      { length: KEYWORDS_SUGGEST.length },
      (v, i) => i
    );
    const suggestList = [];
    for (let i = 0; i < num; i++) {
      var randomIndex = Math.floor(Math.random() * lotArray.length);
      suggestList.push(KEYWORDS_SUGGEST[lotArray[randomIndex]]);
      lotArray.splice(randomIndex, 1);
    }

    return suggestList.map((keyword) => (
      <KeywordLink
        key={keyword}
        to={`/search?q=${encodeURIComponent(keyword)}`}
      >
        {keyword}
      </KeywordLink>
    ));
  }

  return (
    <Wrapper>
      <FirstScreenWrapper>
        <Header />
        <Title>COVID-19 Chinese Archive</Title>
        <Text>
          This database aims to collect and archive Chinese-language online
          contents on COVID-19, including those that have been deleted.
        </Text>
        <SearchBar search={handleSearch} />
        <Text green>Enter "*" for searching all articles</Text>
        <SearchAdvanced />
        <Button onClick={handleClickRandomKeywords}>Random Keywords</Button>
        <SuggestKeywords>
          <Hint>or Try:</Hint>
          <ListWrapper>{renderSuggestKeywords(5)}</ListWrapper>
        </SuggestKeywords>
      </FirstScreenWrapper>
      <Cooperation />
      <Contributors />
      <Footer />
    </Wrapper>
  );
}

export default Home;
