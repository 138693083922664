import styled from "styled-components";

import { COLOR_GRAY_LIGHT } from "../constants/styles";

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  background-color: #fff;
  @media (min-width: 768px) {
    flex-direction: row;
    padding: 32px;
  }
  @media (min-width: 1024px) {
    padding: 48px 60px;
  }
  a {
    @media (min-width: 768px) {
      font-size: 14px;
    }
    @media (min-width: 1024px) {
      font-size: 16px;
    }
    + a {
      margin: 16px 0 0;
      @media (min-width: 768px) {
        margin: 0 0 0 20px;
      }
      @media (min-width: 1024px) {
        margin: 0 0 0 60px;
      }
    }
    + p {
      margin: 24px 0 0;
      @media (min-width: 768px) {
        margin: 0 0 0 auto;
      }
    }
  }
  p {
    color: ${COLOR_GRAY_LIGHT};
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.008em;
  }
`;

function Footer() {
  return (
    <Wrapper>
      <a
        href='https://cov19zharc.net/termsofuse'
        target='_blank'
        rel='noreferrer noopener'
      >
        Terms of Use
      </a>
      <a
        href='https://cov19zharc.net/disclaimer'
        target='_blank'
        rel='noreferrer noopener'
      >
        Disclaimer
      </a>
      <a
        href='https://cov19zharc.net/policy'
        target='_blank'
        rel='noreferrer noopener'
      >
        Privacy Policy
      </a>
      <a
        href='https://cov19zharc.net/fairuse'
        target='_blank'
        rel='noreferrer noopener'
      >
        Fair Use
      </a>
      <a
        href='https://github.com/cov19zharc/covid-archive'
        target='_blank'
        rel='noreferrer noopener'
      >
        Download Data
      </a>
      <a
        href='https://docs.google.com/spreadsheets/d/e/2PACX-1vRqg9Ty8QasPwuZx3_1At7DCds__E8t9fdSunLR9hzLP0_6-C9MpKjzUmiTUllX_g/pubhtml'
        target='_blank'
        rel='noreferrer noopener'
      >
        Authority File
      </a>

      <p>
        Update by <span>2021-01-31</span>
      </p>
    </Wrapper>
  );
}

export default Footer;
