import styled from "styled-components";

import { COLOR_GREEN_TEXT } from "../constants/styles";
import logo1 from "../assets/a.jpg";
import logo2 from "../assets/b.jpg";
import logo3 from "../assets/c.jpg";

const Wrapper = styled.section`
  padding: 48px 0;
  text-align: center;
  @media (min-width: 1024px) {
    padding: 60px 0;
  }
`;

const Heading = styled.p`
  margin: 0 auto 12px;
  color: ${COLOR_GREEN_TEXT};
  font-weight: 200;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.005em;
  @media (min-width: 768px) {
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 44px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 24px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
  a {
    display: block;
    + a {
      margin: 24px 0 0;
      @media (min-width: 768px) {
        margin: 0 0 0 48px;
      }
      @media (min-width: 1024px) {
        margin: 0 0 0 120px;
      }
    }
  }
  img {
    display: block;
    width: 150px;
    height: 100px;
    object-fit: contain;
    object-position: center;
  }
`;

function Partner() {
  return (
    <Wrapper>
      <Heading>Contributors</Heading>
      <List>
        <a href='https://ocf.tw/en/' target='_blank' rel='noreferrer noopener'>
          <img src={logo1} alt='Contributor A' />
        </a>
        <a
          href='https://meta.m.wikimedia.org/wiki/Wikimedia_Taiwan/Wikidata_Taiwan'
          target='_blank'
          rel='noreferrer noopener'
        >
          <img src={logo2} alt='Contributor B' />
        </a>
        <a
          href='https://doublethinklab.org/'
          target='_blank'
          rel='noreferrer noopener'
        >
          <img src={logo3} alt='Contributor C' />
        </a>
      </List>
    </Wrapper>
  );
}

export default Partner;
