import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GRAY_LIGHT,
  COLOR_GRAY_OUTLINE,
  COLOR_GREEN_TEXT,
} from "../constants/styles";
import { getTranslationInfoText } from "../utils";

const Wrapper = styled.div`
  padding: 0 8px;
  @media (min-width: 768px) {
    padding: 0;
  }
`;

const Heading = styled.h3`
  margin: 0;
  color: ${COLOR_GREEN_TEXT};
  font-weight: 200;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
`;

const InfoWrapper = styled.div`
  max-width: 640px;
  margin: 24px auto 0;
  padding: 24px 16px;
  border: 1px solid ${COLOR_GRAY_OUTLINE};
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  @media (min-width: 768px) {
    padding: 32px 20px;
    margin-top: 32px;
  }
`;

const MetaTable = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const MetaTableItem = styled.div`
  display: flex;
  justify-content: space-between;

  &:first-child {
    span {
      margin: 0;
    }
  }

  & + & {
    margin-top: 8px;
  }

  @media (min-width: 768px) {
    justify-content: flex-start;
    width: 50%;

    & + & {
      margin-top: 0;
    }

    &:nth-child(3) {
      span {
        margin-top: 8px;
      }
    }
  }
`;

const MetaItem = styled.div`
  & + & {
    margin-top: 16px;
  }

  @media (min-width: 768px) {
    display: flex;

    & + & {
      margin-top: 9px;
    }
  }
`;

const MetaText = styled.span`
  display: block;
  width: ${(props) => (props.heading ? "120px" : "auto")};
  margin: ${(props) => (props.heading ? 0 : 2)}px 0 0;
  color: ${(props) => (props.heading ? COLOR_GRAY_LIGHT : COLOR_GRAY)};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  @media (min-width: 768px) {
    flex: ${(props) => (props.heading ? "0 1 auto" : 1)};
    padding: 0 0 0 ${(props) => (props.heading ? 0 : 3)}px;
    margin: 0;
  }
  ${(props) =>
    props.link &&
    css`
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: bottom;
      &:hover {
        text-decoration-line: underline;
      }

      @media (min-width: 768px) {
        max-width: 80%;
      }
    `}
`;

const MetaTableText = styled(MetaText)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextLink = styled(Link)`
  display: inline;
  width: ${(props) => (props.heading ? "120px" : "auto")};
  margin: ${(props) => (props.heading ? 0 : 2)}px 0 0;
  color: ${(props) => (props.heading ? COLOR_GRAY_LIGHT : COLOR_GRAY)};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  &:hover {
    text-decoration-line: underline;
  }
  @media (min-width: 768px) {
    flex: ${(props) => (props.heading ? "0 1 auto" : 1)};
    padding: 0 0 0 ${(props) => (props.heading ? 0 : 3)}px;
    margin: 0;
  }
`;

const Divider = styled.div`
  margin: 16px 0 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);

  + div {
    margin-top: 16px;
  }

  @media (min-width: 768px) {
    margin: 20px 0 0;

    + div {
      margin-top: 20px;
    }
  }
`;

function ArticleMeta(props) {
  const articleMeta = props.meta || {};

  function renderKeywords(keywords = "") {
    const items = keywords?.split(",")?.map((item) => item?.trim());
    return items
      .map((keyword) => (
        <TextLink key={keyword} to={`/search?q=${encodeURIComponent(keyword)}`}>
          {keyword}
        </TextLink>
      ))
      .reduce((prev, curr) => [prev, ", ", curr]);
  }

  return (
    <Wrapper>
      <Heading>Article Infomation</Heading>
      <InfoWrapper>
        <MetaTable>
          <MetaTableItem>
            <MetaTableText heading>Published on</MetaTableText>
            <MetaTableText>{articleMeta["Published on"]}</MetaTableText>
          </MetaTableItem>

          <MetaTableItem>
            <MetaTableText heading>Text in English</MetaTableText>
            <MetaTableText>
              {getTranslationInfoText(articleMeta["Text in English"])}
            </MetaTableText>
          </MetaTableItem>

          <MetaTableItem>
            <MetaTableText heading>Media/Platform</MetaTableText>
            <MetaTableText>{articleMeta["Medium/Platform"]}</MetaTableText>
          </MetaTableItem>
        </MetaTable>

        <Divider />

        <MetaItem first>
          <MetaText heading>Original Title</MetaText>
          <MetaText>{articleMeta["原文標題"]}</MetaText>
        </MetaItem>

        <MetaItem>
          <MetaText heading>English Title</MetaText>
          <MetaText>{articleMeta["English title"]}</MetaText>
        </MetaItem>

        <MetaItem>
          <MetaText heading>Source URL</MetaText>
          {articleMeta["Original Url"] && (
            <MetaText
              link
              as='a'
              href={articleMeta["Original Url"]}
              target='_blank'
              rel='noreferrer noopener'
            >
              {articleMeta["Original Url"]}
            </MetaText>
          )}
        </MetaItem>

        <MetaItem>
          <MetaText heading>Archive URL</MetaText>
          {articleMeta["Archive URL"] && (
            <MetaText
              link
              as='a'
              href={articleMeta["Archive URL"]}
              target='_blank'
              rel='noreferrer noopener'
            >
              {articleMeta["Archive URL"]}
            </MetaText>
          )}
        </MetaItem>

        <Divider />

        <MetaItem>
          <MetaText heading>Information Type</MetaText>
          <MetaText>{articleMeta["Information Type"]}</MetaText>
        </MetaItem>

        <MetaItem>
          <MetaText heading>Keywords</MetaText>
          <div>{renderKeywords(articleMeta["Keywords"])}</div>
        </MetaItem>
      </InfoWrapper>
    </Wrapper>
  );
}

export default ArticleMeta;
