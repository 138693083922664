import { useState, useEffect } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as Icon } from "../assets/magnifier.svg";
import {
  COLOR_GREEN,
  COLOR_GRAY_OUTLINE,
  COLOR_GRAY,
} from "../constants/styles";
import { useQuery } from "../utils";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 288px;
  margin: 24px 0 0;
  border: 1px solid
    ${(props) => (props.isFocused ? COLOR_GREEN : COLOR_GRAY_OUTLINE)};
  border-radius: 4px;
  box-shadow: ${(props) =>
    props.isFocused ? "0px 0px 12px -2px rgba(0, 0, 0, 0.2)" : ""};
  @media (min-width: 768px) {
    max-width: 560px;
    margin: 48px 0 0;
  }
  &:hover {
    border: 1px solid ${COLOR_GRAY};
    box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.2);
  }
  ${(props) =>
    props.result &&
    css`
      max-width: none;
      margin: 0;
      @media (min-width: 768px) {
        margin: 0;
      }
    `}
`;

const Button = styled.button`
  display: block;
  width: 56px;
  padding: 0;
  background-color: transparent;
  border: none;
`;

const StyledIcon = styled(Icon)`
  path {
    fill: ${(props) => (props.isFocused ? COLOR_GREEN : COLOR_GRAY_OUTLINE)};
  }
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 17px 16px 17px 0;
  font-size: 16px;
  line-height: 22px;
  text-indent: 4px;
  letter-spacing: 0.005em;
  background-color: transparent;
  outline: none;
  border: none;

  &::placeholder {
    color: ${COLOR_GRAY_OUTLINE};
  }
`;

function SearchBar(props) {
  const query = useQuery().get("q") || "";

  const [isFocused, setIsFocused] = useState(false);
  const [isCompositing, setIsCompositing] = useState(false);
  const [keywords, setKeywords] = useState(query);

  useEffect(() => {
    setKeywords(query);
  }, [query]);

  function handleKeyDown(e) {
    if (e.key === "Enter" && !isCompositing) {
      props.search(keywords);
    }
  }

  return (
    <Wrapper result={props.result} isFocused={isFocused}>
      <Button onClick={() => props.search(keywords)}>
        <StyledIcon />
      </Button>
      <SearchInput
        value={keywords}
        onCompositionStart={() => setIsCompositing(true)}
        onCompositionEnd={() => setIsCompositing(false)}
        onChange={(e) => setKeywords(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        placeholder='Search for...'
      />
    </Wrapper>
  );
}

export default SearchBar;
