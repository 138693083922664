import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GRAY_LIGHT,
  COLOR_GREEN_TEXT,
} from "../constants/styles";

const Wrapper = styled.main`
  margin: 48px 0 0;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0;
  }

  > h1,
  > p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  > h1 {
    max-width: 528px;
  }
`;

const Title = styled.h1`
  margin: 0;
  color: ${COLOR_GRAY};
  font-size: 28px;
  font-weight: 300;
  line-height: 36px;
  text-align: center;
  + div {
    margin-top: 24px;
    @media (min-width: 768px) {
      margin-top: 32px;
    }
  }
`;

const Content = styled.div`
  max-width: 528px;
  margin: 0 auto;
  word-wrap: break-word;
  @media (min-width: 1024px) {
    max-width: 560px;
  }
`;

const MoreInfo = styled.div`
  margin: 22px 0 0;
`;

const Info = styled.p`
  text-align: center;
  color: ${COLOR_GRAY_LIGHT};
  a {
    color: ${COLOR_GREEN_TEXT};
    &:hover {
      text-decoration-line: underline;
    }
  }
`;

function ArticleContent(props) {
  return (
    <Wrapper>
      <Title>{props.content?.["English title"]}</Title>
      <Content>
        {props.content?.["English content"]}
        {props.content["English content"] ? "..." : ""}
      </Content>
      <MoreInfo>
        {props.originalUrl && (
          <Info>
            <span>Please click the </span>
            <a
              href={props.originalUrl}
              target='_blank'
              rel='noreferrer noopener'
            >
              Source URL
            </a>
            <span> or </span>
            <a
              href={props.archiveUrl}
              target='_blank'
              rel='noreferrer noopener'
            >
              Archive URL
            </a>
            <span> if you would like to read the original text.</span>
          </Info>
        )}
        <Info>
          This translation only translated partial text of the article.
        </Info>
      </MoreInfo>
    </Wrapper>
  );
}

export default ArticleContent;
