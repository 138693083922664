import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { metaState } from "../reducers/metaSlice";
import { contentState } from "../reducers/contentSlice";
import ArticleContent from "./ArticleContent";
import ArticleMeta from "./ArticleMeta";
import Contributors from "./Contributors";
import Cooperation from "./Cooperation";
import Footer from "./Footer";
import Header from "./Header";

import { fetchMetaData } from "../reducers/metaSlice";

const Wrapper = styled.section`
  min-height: 100vh;
  padding: 96px 0 0;
  @media (min-width: 768px) {
    padding: 120px 0 0;
  }
  @media (min-width: 1024px) {
    padding: 128px 0 0;
  }
`;

const Text = styled.p`
  text-align: center;
`;
const defaultArticleMeta = {
  "Archive URL": "",
  "English content": "",
  "English title": "",
  "Information Type": "",
  Keywords: "",
  "Medium/Platform": "",
  "Original Url": "",
  "Published on": "",
  "Text in English": "",
  slug: "6289",
  原文標題: "",
};
const defaultArticleContent = {
  "English content": "",
  "English title": "",
  slug: "6289",
  原文標題: "...",
};

function Article() {
  const [mounted, setMounted] = useState(false);

  const slug = useParams().slug;
  defaultArticleMeta["slug"] = slug;
  defaultArticleContent["slug"] = slug;

  const metaList = useSelector(metaState) || [];
  const articleMeta =
    metaList.find((item) => item.slug === slug) || defaultArticleMeta;
  const contentList = useSelector(contentState) || [];
  const articleContent =
    contentList.find((item) => item.slug === slug) || defaultArticleContent;

  useEffect(() => {
    setMounted(true);
    window?.ga("send", "pageview", `${window.location.pathname}`, {
      location: window.location.href,
      title: articleContent["English title"],
    });
  }, [articleContent]);

  const hasArticle = articleMeta || articleContent;

  return (
    <Wrapper>
      <Header />
      {mounted && articleMeta && <ArticleMeta meta={articleMeta} />}
      {mounted && articleContent && (
        <ArticleContent
          content={articleContent}
          originalUrl={articleMeta?.["Original Url"]}
          archiveUrl={articleMeta?.["Archive URL"]}
        />
      )}
      {mounted && !hasArticle && <Text>Not found.</Text>}
      <Cooperation isArticle />
      <Contributors />
      <Footer />
    </Wrapper>
  );
}

export default Article;
