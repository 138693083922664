import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";

import { COLOR_GRAY } from "../constants/styles";
import {
  setState as setPlatformsSelectedState,
  platformsSelectedState,
} from "../reducers/platformsSelectedSlice";
import {
  setState as setTranslationSelectedState,
  translationSelectedState,
} from "../reducers/translationSelectedSlice";
import {
  setState as setTimeSelectedState,
  timeSelectedState,
} from "../reducers/timeSelectedSlice";
import {
  CHOICES_PLATFORMS,
  CHOICES_TRANSLATION,
  PLATFORMS_TRANSLATION,
} from "../constants/search";
import { getDate } from "../utils";
import CheckboxSelector from "./CheckboxSelector";
import DateSelector from "./DateSelector";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 560px;
  margin: 24px 0 0;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    position: relative;
  }
`;

const TypeButton = styled.button`
  padding: 4px 10px;
  color: #9f9f9f;
  font-size: 16px;
  line-height: 22px;
  background-color: transparent;
  border: none;

  + div {
    margin-top: 16px;
    @media (min-width: 768px) {
      margin: 0;
    }
  }

  :not(:first-child) {
    margin: 8px 0 0;
    @media (min-width: 768px) {
      margin: 0 0 0 50px;
    }
  }

  ::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 0 0 10px;
    border-left: 1px solid #9f9f9f;
    border-bottom: 1px solid #9f9f9f;
    transform: translateY(-4px) rotate(-45deg);
  }

  &:hover {
    color: ${COLOR_GRAY};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${COLOR_GRAY};
      ::after {
        border-color: ${COLOR_GRAY};
        transform: translateY(0px) rotate(135deg);
      }
    `}
`;

const SelectorWrapper = styled.div`
  display: none;
  width: 100%;
  max-width: 560px;
  padding: ${(props) => (props.time ? "24px" : "8px")} 0 0;
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    position: absolute;
    top: 38px;
    left: 0;
    justify-content: center;
    align-items: center;
  }

  div + p {
    @media (min-width: 768px) {
      margin: 0 0 0 24px;
    }
  }

  p {
    margin: 0 0 8px;
    color: #9f9f9f;
    font-size: 14px;
    line-height: 20px;

    :not(:first-child) {
      margin: 8px 0;
    }

    @media (min-width: 768px) {
      margin: 0;

      :not(:first-child) {
        margin: 0 0 0 60px;
      }

      + div {
        margin: 0 0 0 24px;
      }
    }
  }

  ${(props) =>
    props.active &&
    css`
      display: block;
      @media (min-width: 768px) {
        display: flex;
      }

      + button:not(:first-child) {
        margin-top: 48px;
        @media (min-width: 768px) {
          margin-top: 0;
        }
      }
    `}
`;

function SearchAdvanced() {
  const dispatch = useDispatch();
  const platformsSelected = useSelector(platformsSelectedState);
  const translationSelected = useSelector(translationSelectedState);
  const timeSelected = useSelector(timeSelectedState);

  const [currentType, setCurrentType] = useState(null);

  function handleClick(type) {
    if (currentType === type) {
      return setCurrentType(null);
    }
    return setCurrentType(type);
  }

  function handlePlatformsChange(e) {
    const value = e.target.value;
    if (e.target.checked) {
      if (value === "all") {
        return dispatch(setPlatformsSelectedState(["all"]));
      }
      return dispatch(
        setPlatformsSelectedState([
          ...platformsSelected.filter((item) => item !== "all"),
          value,
          PLATFORMS_TRANSLATION[value],
        ])
      );
    } else {
      return dispatch(
        setPlatformsSelectedState(
          platformsSelected.filter((item) => item !== value)
        )
      );
    }
  }

  function handleTranslationChange(e) {
    if (e.target.checked) {
      dispatch(
        setTranslationSelectedState([...translationSelected, e.target.value])
      );
    } else {
      dispatch(
        setTranslationSelectedState(
          translationSelected.filter((item) => item !== e.target.value)
        )
      );
    }
  }

  function handleDateChange(value, key) {
    dispatch(setTimeSelectedState({ value, key }));
  }

  return (
    <Wrapper>
      <TypeButton
        active={currentType === "time"}
        onClick={() => handleClick("time")}
      >
        Time
      </TypeButton>
      <SelectorWrapper time active={currentType === "time"}>
        <p>From</p>
        <DateSelector onChange={(value) => handleDateChange(value, "from")} />
        <p>To</p>
        <DateSelector
          year={getDate(timeSelected.to, "year")}
          month={getDate(timeSelected.to, "month")}
          onChange={(value) => handleDateChange(value, "to")}
        />
      </SelectorWrapper>
      <TypeButton
        active={currentType === "platform"}
        onClick={() => handleClick("platform")}
      >
        Platforms
      </TypeButton>
      <SelectorWrapper active={currentType === "platform"}>
        <CheckboxSelector
          platform
          choices={CHOICES_PLATFORMS}
          selected={platformsSelected}
          onChange={(e) => handlePlatformsChange(e)}
        />
      </SelectorWrapper>
      <TypeButton
        active={currentType === "translate"}
        onClick={() => handleClick("translate")}
      >
        Text in English
      </TypeButton>
      <SelectorWrapper active={currentType === "translate"}>
        <CheckboxSelector
          choices={CHOICES_TRANSLATION}
          selected={translationSelected}
          onChange={(e) => handleTranslationChange(e)}
        />
      </SelectorWrapper>
    </Wrapper>
  );
}

export default SearchAdvanced;
