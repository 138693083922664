import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { COLOR_GREEN_TEXT, COLOR_GRAY_LIGHT } from "../constants/styles";
import { getTranslationInfoText } from "../utils";

const Item = styled.div`
  padding: 12px 16px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    max-width: 536px;
    padding: 0;
    margin: 0 auto;
    border: none;
  }

  & + & {
    margin-top: 8px;
    @media (min-width: 768px) {
      margin-top: 32px;
    }
  }

  + div,
  + footer {
    margin-top: 8px;
  }
`;

const Title = styled.h2`
  margin: 2px 0 0;
  color: ${COLOR_GREEN_TEXT};
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  &:hover {
    text-decoration-line: underline;
  }
`;

const SubTitle = styled.h3`
  margin: 2px 0 0;
  font-size: 15px;
  font-weight: 300;
`;

const Info = styled.div`
  margin: 12px 0 0;
`;

const InfoText = styled.span`
  margin: 0 0 0 ${(props) => (props.heading ? 0 : 6)}px;
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;

  ${(props) =>
    props.heading &&
    css`
      color: ${COLOR_GRAY_LIGHT};
      word-break: normal;
    `}
  ${(props) =>
    props.link &&
    css`
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: bottom;
      &:hover {
        text-decoration-line: underline;
      }

      @media (min-width: 768px) {
        max-width: 80%;
      }
    `}
`;

const InfoTextLink = styled(Link)`
  margin: 0 0 0 ${(props) => (props.heading ? 0 : 6)}px;
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
  &:hover {
    text-decoration-line: underline;
  }

  ${(props) =>
    props.heading &&
    css`
      color: ${COLOR_GRAY_LIGHT};
      word-break: normal;
    `}
`;

function SearchResultItem(props) {
  const article = props.article || {};

  function getHref() {
    return `/article/${article.slug}`;
  }

  function renderKeywords(keywords = "") {
    const items = keywords?.split(",")?.map((item) => item?.trim());
    return items
      .map((keyword) => (
        <InfoTextLink
          key={keyword}
          to={`/search?q=${encodeURIComponent(keyword)}`}
        >
          {keyword}
        </InfoTextLink>
      ))
      .reduce((prev, curr) => [prev, ", ", curr]);
  }

  return (
    <Item>
      <InfoText heading>{article["Information Type"]}</InfoText>
      <Link to={getHref()}>
        <Title>{article["English title"]}</Title>
      </Link>
      <Link to={getHref()}>
        <SubTitle>{article["原文標題"]}</SubTitle>
      </Link>
      <Info>
        <p>
          <InfoText heading>Published on:</InfoText>
          <InfoText>{article["Published on"]}</InfoText>
        </p>
        <p>
          <InfoText heading>Source URL:</InfoText>
          {article["Original Url"] && (
            <InfoText
              link
              as='a'
              href={article["Original Url"]}
              target='_blank'
              rel='noreferrer noopener'
            >
              {article["Original Url"]}
            </InfoText>
          )}
        </p>
        <p>
          <InfoText heading>Archive URL:</InfoText>
          {article["Archive URL"] && (
            <InfoText
              link
              as='a'
              href={article["Archive URL"]}
              target='_blank'
              rel='noreferrer noopener'
            >
              {article["Archive URL"]}
            </InfoText>
          )}
        </p>
        <p>
          <InfoText heading>Media/Platform:</InfoText>
          <InfoText>{article["Medium/Platform"]}</InfoText>
        </p>
        <p>
          <InfoText heading>Keywords:</InfoText>
          {renderKeywords(article["Keywords"])}
        </p>
        <p>
          <InfoText heading>Text in English:</InfoText>
          <InfoText>
            {getTranslationInfoText(article["Text in English"])}
          </InfoText>
        </p>
      </Info>
    </Item>
  );
}

export default SearchResultItem;
