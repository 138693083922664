import { useState } from "react";
import styled, { css } from "styled-components";
import logo from "../assets/logo.png";

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 16px;
  @media (min-width: 768px) {
    height: 88px;
    padding: 0 32px 0 24px;
  }
  @media (min-width: 1024px) {
    height: 80px;
  }
  a {
    display: block;
  }

  ${(props) =>
    props.result &&
    css`
      height: 80px;
      background-color: #fff;
      @media (min-width: 768px) {
        justify-content: space-between;
      }
      > a {
        display: none;
        @media (min-width: 768px) {
          display: block;
        }
      }
    `}
`;

const Icon = styled.img`
  display: block;
  width: ${(props) => (props.share ? 40 : 44)}px;
  height: ${(props) => (props.share ? 40 : 44)}px;
`;

const ShareWrapper = styled.div`
  position: relative;
  margin: 0 0 0 auto;

  button {
    display: block;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  ${(props) =>
    props.active &&
    css`
      a {
        display: block !important;
      }
    `}

  ${(props) =>
    props.result &&
    css`
      display: none;
      @media (min-width: 768px) {
        display: block;
        margin: 0;
      }
    `}
`;

const ShareLink = styled.a`
  display: none !important;
  position: absolute;
  top: 50px;
  ${(props) =>
    props.second &&
    css`
      top: 100px;
    `}
`;

function Header(props) {
  const [isExpandedShareItems, setIsExpandedShareItems] = useState(false);

  function handleClick() {
    setIsExpandedShareItems(!isExpandedShareItems);
  }

  return (
    <Wrapper result={props.result}>
      <a href='/'>
        <Icon src={logo} alt='logo' />
      </a>
      {props.children}
      <ShareWrapper active={isExpandedShareItems} result={props.result}>
        <button onClick={handleClick}>
          <Icon share src={require("../assets/share.svg").default} alt='分享' />
        </button>
        <ShareLink
          href={`https://www.facebook.com/share.php?u=https://cov19zharc.github.io/`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Icon
            share
            src={require("../assets/facebook.png").default}
            alt='分享至 Facebook'
          />
        </ShareLink>
        <ShareLink
          second
          href={`https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
            "https://cov19zharc.github.io/"
          )}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Icon
            share
            src={require("../assets/line.png").default}
            alt='分享至 Line'
          />
        </ShareLink>
      </ShareWrapper>
    </Wrapper>
  );
}

export default Header;
