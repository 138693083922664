import { useLocation } from "react-router-dom";

export function getTranslationInfoText(value) {
  return value === "Y" ? "Non-Google translate" : "Google translate";
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getDate(value, column = "year") {
  const split = value.split("-");
  return column === "year" ? split[0] : split[1];
}
