import { Link } from "react-router-dom";
import { chunk } from "lodash";
import { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import {
  COLOR_GRAY_LIGHT,
  COLOR_GREEN,
  COLOR_GREEN_TEXT,
} from "../constants/styles";
import { metaState } from "../reducers/metaSlice";
import {
  platformsSelectedState,
  selectAllPlatforms,
} from "../reducers/platformsSelectedSlice";
import { translationSelectedState } from "../reducers/translationSelectedSlice";
import { timeSelectedState } from "../reducers/timeSelectedSlice";
import { getTranslationInfoText, useQuery } from "../utils";
import Footer from "./Footer";
import Header from "./Header";
import SearchBar from "./SearchBar";
import SearchResultItem from "./SearchResultItem";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 80px 0 0;
  background-color: #f9f9f9;
  @media (min-width: 768px) {
    padding: 88px 0 0;
    background-color: transparent;
  }
`;

const WarningText = styled.p`
  max-width: 536px;
  margin: 24px auto 0;
  padding: 0 16px;
  color: ${COLOR_GRAY_LIGHT};
  text-align: center;
  @media (min-width: 768px) {
    margin-top: 60px;
    font-size: 20px;
    line-height: 28px;
  }

  + p {
    margin-top: 12px;
  }

  a,
  span {
    color: ${COLOR_GREEN_TEXT};
  }
`;

const Info = styled.div`
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  padding: 12px 16px 0;
  background-color: #fff;
  @media (min-width: 768px) {
    padding: 0;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const InfoText = styled.p`
  color: ${COLOR_GRAY_LIGHT};
  font-size: 14px;
  line-height: 20px;
`;

const InfoSortBtn = styled(InfoText)`
  color: #333;
  background-color: transparent;
  border: none;
  ::before {
    content: "↓";
    margin: 0 6px 0 0;
    vertical-align: text-bottom;
  }
  ${(props) =>
    props.desc &&
    css`
      ::before {
        content: "↑";
      }
    `}
`;

const List = styled.div`
  flex: 1;
  @media (min-width: 768px) {
    margin: 12px 0 0;
  }
  + footer {
    margin-top: 8px;
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
`;

const LoadMoreBtn = styled.button`
  display: block;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.005em;
  width: 100%;
  padding: 12px;
  margin: 8px auto;
  color: ${COLOR_GREEN_TEXT};
  background-color: #fff;
  border: none;
  box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.1),
    inset 0px 0.5px 0px rgba(0, 0, 0, 0.1);

  &:hover,
  &:active {
    background: linear-gradient(
        0deg,
        rgba(0, 181, 173, 0.05),
        rgba(0, 181, 173, 0.05)
      ),
      #ffffff;
  }

  @media (min-width: 768px) {
    width: 240px;
    margin: 32px auto 0;
    padding: 8px 0;
    border: 1px solid ${COLOR_GREEN};
    border-radius: 2px;

    &:hover,
    &:active {
      background: rgba(16, 155, 149, 0.05);
    }
  }
`;

function SearchResult() {
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const history = useHistory();
  const query = useQuery().get("q");
  const metaFromState = useSelector(metaState);
  const articleMeta = useMemo(() => metaFromState, [metaFromState]);

  const platformsSelected = useSelector(platformsSelectedState);
  const isAllPlatforms = useSelector(selectAllPlatforms);
  const translationSelected = useSelector(translationSelectedState);
  const timeSelected = useSelector(timeSelectedState);

  const queryLowerCase = query?.toLowerCase();

  const articlesFiltered = useMemo(() => {
    // 過濾 Text in English
    let result = articleMeta.filter((item) =>
      translationSelected.includes(
        getTranslationInfoText(item["Text in English"])
      )
    );

    result.map((item) => {
      if (!item["Text in English"]) console.log(1);
    });

    // 過濾 searchbar input 所打的字詞
    if (queryLowerCase !== "*") {
      result = result.filter(
        (item) =>
          item["原文標題"]?.includes(queryLowerCase) ||
          item["English title"]?.toLowerCase()?.includes(queryLowerCase) ||
          item["Keywords"]?.toLowerCase()?.includes(queryLowerCase) ||
          item["Medium/Platform"]?.toLowerCase()?.includes(queryLowerCase)
      );
    }
    // 過濾時間
    result = result.filter((item) => {
      return (
        item["Published on"] >= `${timeSelected.from}-01` &&
        item["Published on"] <= `${timeSelected.to}-31`
      );
    });

    // 過濾 Medium/Platform
    if (!isAllPlatforms) {
      return result.filter((item) => {
        let isMatch = (target) => item["Medium/Platform"].includes(target);
        return platformsSelected.some(isMatch);
      });
    }

    // 排序
    result = result.sort((a, b) => {
      if (sortingOrder === "asc") {
        return a["Published on"].localeCompare(b["Published on"]);
      }
      return b["Published on"].localeCompare(a["Published on"]);
    });

    return result;
  }, [
    articleMeta,
    translationSelected,
    queryLowerCase,
    timeSelected,
    isAllPlatforms,
    platformsSelected,
    sortingOrder,
  ]);

  useEffect(() => {
    window?.ga("send", "pageview", `${window.location.pathname}?q=${query}`, {
      location: window.location.href,
    });
  }, [query]);

  const articlesFilteredByPage = useMemo(() => {
    const articlesChunk = chunk(articlesFiltered, 10);
    setMaxPage(articlesChunk.length);
    return articlesChunk;
  }, [articlesFiltered]);

  function handleSearch(keyword) {
    history.push(`/search/?q=${keyword}`);
  }

  function handleSortingBtnClick() {
    if (sortingOrder === "asc") {
      return setSortingOrder("desc");
    }
    return setSortingOrder("asc");
  }

  function renderResult() {
    if (articlesFiltered.length) {
      let items = [];
      for (let i = 1; i <= page; i += 1) {
        articlesFilteredByPage[i - 1].map((article) =>
          items.push(
            <SearchResultItem key={article["slug"]} article={article} />
          )
        );
      }
      return items;
    }
    return null;
  }

  function handleLoadMore() {
    setPage(page + 1);
  }

  return (
    <Wrapper>
      <Header result='result'>
        <SearchBar
          result='result'
          search={(keyword) => handleSearch(keyword)}
        />
      </Header>
      <Info>
        <div>
          <InfoText>{articlesFiltered.length} results</InfoText>
          <InfoSortBtn
            as='button'
            desc={sortingOrder === "desc"}
            onClick={() => handleSortingBtnClick()}
          >
            Sort by: {sortingOrder === "asc" ? "Oldest first" : "Latest first"}
          </InfoSortBtn>
        </div>
      </Info>
      <List>
        {renderResult(articlesFilteredByPage)}
        {!articlesFiltered.length && (
          <WarningText>
            {`Sorry, according to your search criteria, this archive couldn’t find any results matching "`}
            {query && !articlesFiltered.length ? <span>{query}</span> : ""}
            {query && !articlesFiltered.length ? '"' : ""}
          </WarningText>
        )}
        {!articlesFiltered.length && (
          <WarningText>
            Or entering "*" for searching{" "}
            <Link to='/search?q=*'>all articles.</Link>
          </WarningText>
        )}
        {page < maxPage && (
          <LoadMoreBtn onClick={() => handleLoadMore()}>
            More results
          </LoadMoreBtn>
        )}
      </List>
      <Footer />
    </Wrapper>
  );
}

export default SearchResult;
