import { configureStore } from '@reduxjs/toolkit';
import metaReducer from './reducers/metaSlice';
import contentReducer from './reducers/contentSlice'
import platformsSelectedReducer from './reducers/platformsSelectedSlice'
import translationSelectedReducer from './reducers/translationSelectedSlice'
import timeSelectedReducer from './reducers/timeSelectedSlice'

export default configureStore({
  reducer: {
    content: contentReducer,
    meta: metaReducer,
    platformsSelected: platformsSelectedReducer,
    translationSelected: translationSelectedReducer,
    timeSelected: timeSelectedReducer,
  },
});
