import { createSlice } from "@reduxjs/toolkit";

import { CHOICES_TRANSLATION } from "../constants/search";

export const translationSelectedSlice = createSlice({
  name: "translationSelected",
  initialState: {
    value: CHOICES_TRANSLATION,
  },
  reducers: {
    setState: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setState } = translationSelectedSlice.actions;

export const translationSelectedState = (state) =>
  state.translationSelected.value;

export default translationSelectedSlice.reducer;
