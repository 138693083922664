import { useState, useEffect } from "react";
import axios from "axios";
import styled, { css } from "styled-components";

import { COLOR_GREEN } from "../constants/styles";

const Wrapper = styled.section`
  padding: 60px 0;
  color: #ffffff;
  text-align: center;
  background-color: ${COLOR_GREEN};
  ${(props) =>
    props.isArticle &&
    css`
      margin: 48px 0 0;
      @media (min-width: 1024px) {
        margin: 60px 0 0;
      }
    `}
`;

const Text = styled.p`
  max-width: 288px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    max-width: 440px;
  }
  ${(props) =>
    props.heading &&
    css`
      margin: 0 auto;
      font-size: 32px;
      font-weight: 200;
      line-height: 40px;
      + p {
        margin-top: 12px;
      }
    `}
`;

const LinkWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    margin: 32px 0 0;
  }
  @media (min-width: 1024px) {
    margin: 48px 0 0;
  }
`;

const Link = styled.a`
  display: block;
  width: 200px;
  padding: 12px 0;
  margin: ${(props) => (props.first ? 24 : 16)}px auto 0;
  color: ${(props) => (props.first ? COLOR_GREEN : "#FFFFFF")};
  background-color: ${(props) => (props.first ? "#FFFFFF" : "transparent")};
  border: ${(props) => (props.first ? "none" : "1px solid #FFFFFF")};
  border-radius: 2px;
  box-shadow: ${(props) =>
    props.first ? "0px 0px 4px rgba(0, 0, 0, 0.3)" : "#FFFFFF"};
  &:hover {
    background: ${(props) =>
      props.first
        ? `linear-gradient(
      0deg,
      rgba(0, 181, 173, 0.05),
      rgba(0, 181, 173, 0.05)
    ),
    #ffffff`
        : `rgba(16, 155, 149, 0.05)`};
  }
  @media (min-width: 768px) {
    margin: 0;

    + a {
      margin-left: 24px;
    }
  }
`;

function Cooperation({ isArticle }) {
  const [randomUrl, setRandomUrl] = useState(null);
  useEffect(() => {
    getTranslateUrl();
    async function getTranslateUrl() {
      const { data = [] } = await axios.get(
        "https://raw.githubusercontent.com/cov19zharc/covid-archive/master/data/translateurl_for_website.json"
      );
      if (data?.length) {
        const randomIndex = Math.floor(Math.random() * Math.floor(data.length));
        // console.log(data[randomIndex]["顯示在網站上的"]);
        setRandomUrl(data[randomIndex]?.["顯示在網站上的"]);
      }
    }
  }, []);

  return (
    <Wrapper isArticle={isArticle}>
      <Text heading>Join Us</Text>
      <Text>
        Would you like to see more new contents and more English contents in
        this archive?
      </Text>
      <Text>Click the buttons below and you can make it happen!</Text>
      <LinkWrapper>
        <Link
          first
          href='https://forms.gle/wfJ26AMJFhXucj268'
          target='_blank'
          rel='noreferrer noopener'
        >
          Upload New Data
        </Link>
        <Link href={randomUrl} target='_blank' rel='noreferrer noopener'>
          Translate Contents
        </Link>
      </LinkWrapper>
    </Wrapper>
  );
}

export default Cooperation;
