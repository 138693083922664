import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

export const contentSlice = createSlice({
  name: 'content',
  initialState: {
    value: [],
  },
  reducers: {
    setState: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setState } = contentSlice.actions;

export const fetchContentData =  () => async (dispatch) => {
  const { data = [] } = await axios.get('https://raw.githubusercontent.com/cov19zharc/covid-archive/master/data/content_for_website.json')
  dispatch(setState(data))
};

export const contentState = state => state.content.value;

export default contentSlice.reducer;
