import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const metaSlice = createSlice({
  name: "meta",
  initialState: {
    value: [],
  },
  reducers: {
    setState: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setState } = metaSlice.actions;

export const fetchMetaData = () => async (dispatch) => {
  // console.log("ready to fetch data in redux");
  const { data = [] } = await axios.get(
    "https://raw.githubusercontent.com/cov19zharc/covid-archive/master/data/meta_for_website.json"
  );
  dispatch(setState(data));
};

export const metaState = (state) => state.meta.value;

export default metaSlice.reducer;
