import styled, { css } from 'styled-components';

import { COLOR_GRAY_LIGHT } from '../constants/styles'

const Wrapper = styled.div`
  color: ${COLOR_GRAY_LIGHT};
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  label {
    margin: 0 0 0 4px;
  }
  ${props => props.platform && css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    label {
      flex: 1;
    }
    @media (min-width: 1024px) {
      min-width: 900px;
    }
  `}
`

const CheckboxItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0;
  @media (min-width: 768px) {
    margin: 16px 16px 0;
  }
  & + & {
    margin: 16px 0 0;
    @media (min-width: 768px) {
      margin: 16px 16px 0;
    }
  }
`

const CheckboxInput = styled.input`
  width: 16px;
  height: 16px;
  /* visibility: hidden; */
  cursor: pointer;

  /* &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid ${COLOR_GRAY_LIGHT};
  } */
`

function CheckboxSelector(props) {
  const items = props.choices ?? []

  function isChecked(value) {
    return props.selected?.includes?.(value)
  }
  
  return (
    <Wrapper platform={props.platform}>
      {
        items.map((item) => {
          return (
            <CheckboxItem key={item}>
              <CheckboxInput
                type="checkbox"
                value={item}
                onChange={(e) => props.onChange(e)}
                checked={isChecked(item)}
              />
              <label>{ item }</label>
            </CheckboxItem>
          )
        })
      }
    </Wrapper>
  )
}

export default CheckboxSelector;