import { createSlice } from '@reduxjs/toolkit';

export const platformsSelectedSlice = createSlice({
  name: 'platformsSelected',
  initialState: {
    value: ['all']
  },
  reducers: {
    setState: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setState } = platformsSelectedSlice.actions

export const platformsSelectedState = state => state.platformsSelected.value

export const selectAllPlatforms = state => state.platformsSelected.value.includes('all')

export default platformsSelectedSlice.reducer
