export const CHOICES_PLATFORMS = [
  "all",
  "Weixin",
  "Weibo",
  "douban",
  "National Health Commission of the People’s Republic of China",
  "Matters",
  "Zhihu",
  "Xinhuanet",
  "The Paper",
  "Caixin",
];

export const PLATFORMS_TRANSLATION = {
  Weixin: "微信",
  Weibo: "微博",
  douban: "豆瓣",
  "National Health Commission of the People’s Republic of China":
    "卫生健康委员会",
  Zhihu: "知乎",
  Xinhuanet: "新华网",
  "The Paper": "澎湃新闻",
  Caixin: "财新网",
  Matters: "Matters",
};

export const CHOICES_TRANSLATION = ["Google translate", "Non-Google translate"];

export const KEYWORDS_SUGGEST = [
  "Wuhan",
  "pneumonia",
  "isolation",
  "Mask",
  "Detection",
  "Prevention and control",
  "Lockdown",
  "Nucleic acid",
  "Hubei",
  "Health Committee",
  "community",
  "fever",
  "Li Wenliang",
  "SARS",
  "Reconciliation",
  "Whistleblower",
  "Positive",
  "government",
  "Netizen",
  "Mobile cabin",
  "Surveillance",
  "Propaganda",
  "Health Code",
  "delete",
  "Huanan Seafood Wholesale Market",
  "Ai Fen",
];
