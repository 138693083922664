import { createSlice } from '@reduxjs/toolkit';

const now = new Date()
  const currentYear = now.getFullYear()
  const currentMonth = `${now.getMonth() + 1}`.padStart(2, '0')

export const timeSelectedSlice = createSlice({
  name: 'timeSelected',
  initialState: {
    from: '2019-01',
    to: `${currentYear}-${currentMonth}`,
  },
  reducers: {
    setState: (state, action) => {
      state[action.payload.key] = action.payload.value
    }
  }
})

export const { setState } = timeSelectedSlice.actions

export const timeSelectedState = state => state.timeSelected

export default timeSelectedSlice.reducer
